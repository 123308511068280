html, body {
  height: 100%;
}

.App {
  height: 100%;
}

.page_body {
  height: 100%;
  background: #187663;
}

#root {
  background-color: #187663;
  height: 100%;
  overflow-y: auto;
}

a {
  cursor: pointer;
}


.container {
  display: flex;
}

.main-content {
  order: 2; /* Set main content to appear second */
  width: 75%; /* Set main content width as 75% of container */
}

.journal {
  order: 3;
  width: 100%;
}

.logo {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.home-welcome {
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  background: #feebcb;
  height: auto;
}


.home-welcome .container {
  display: flex;
  flex-direction: row;
  height: 100%;
  background: #feebcb;
}

.home-welcome #journal-box {
  justify-content: center;
  align-items: center;
}

.home-welcome #journal {
  background: #7abbb3;
  border-radius: 10px;
  max-width: 900px;
  box-sizing: border-box;
  width: 90%;
}

.sign-in-prompt {
  align-self: center;
  text-align: center;
  width: 100%;
}

.sign-in-button {
  cursor: pointer;
  background-color: #feebcb;
  font-weight: bold;
  border: 3px solid black;
  color: black;
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;
}

@media (max-width: 950px) {
  .page_body {
    background: #feebcb;
  }
  #root {
    background: #feebcb;
  }
}