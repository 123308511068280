.entry p {
    word-break: break-all;
    white-space: normal;
}

#journal-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#prev-journal-list {
    display: flex;
    justify-content: space-evenly;
}

#entries-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.new-entry-button {
    align-self: center;
    background: none;
    border: none;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    color: black;
}

#delete-button {
    margin: 5px;
    border: 3px solid darkred;
    border-radius: 5px;
    width: 100px;
    align-self: center;
    background: floralwhite;
    color: black;
}

#save-button {
    margin: 5px;
    border: 3px solid darkgreen;
    border-radius: 5px;
    width: 100px;
    align-self: center;
    background: floralwhite;
    color: black;
}

#edit-button {
    margin: 5px;
    border: 3px solid darkgreen;
    border-radius: 5px;
    width: 100px;
    align-self: center;
    background: floralwhite;
    color: black;
}

.save-delete-button {
    align-self: center;
    background: none;
    border: none;
}

.entry {
    width: 100%;
}

#entry-outer-layer {
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    box-sizing: border-box;
    margin-top: 15px;
}

.icon {
    height: 1.5em;
    color: black;
    cursor: pointer;
}

#success-message {
    margin: 5px;
    color: black;
}

.save_delete_buttons {
    display: flex;
    flex-direction: row;
}

#journal-text-entry {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    width: 100%;
}

.journal-header {
    align-self: center;
    margin: 0;
}

.entry-list {
    display: flex;
    border-top: 2px dashed black;
}

.journal-date {
    margin: 0;
    padding-top: 20px;
}

.entries-subheader {
    margin-bottom: 0;
    margin-top: 15px;
}

#journal {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}
