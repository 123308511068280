@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
}

.navbar .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    height: 60px;
    background-color: #187663;
    position: relative;
    border-bottom: 10px solid black;
    max-width: 1080px;
    margin: 0 auto !important;
    float: none !important;
    z-index: 100;
}

.logo {
    /*align-self: flex-end;*/
}

#logo {
    background: #feebcb;
    border: 3px solid #000;
    border-radius: 10px;
}

.menu-icon {
    display: none;
}

.nav-elements {
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 60px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #feebcb;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #feebcb;
}

.menu-icon__hamburger {
  font-size: 36px;
  line-height: 1;
    padding: 10px;
}


@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media (min-width: 0px) {
    .menu-icon {
        border: 3px solid black;
        padding: 0px 20px 5px 20px;
        border-radius: 10px;
        background: #feebcb;
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        left: 0;
        top: 102px;
        background-color: #7abbb3;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
    }


    .nav-elements.active {
        width: 240px;
        position: fixed;
        /*top: 0;*/
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
    }




    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }
}

header {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  
  .navbar {
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .navbar__brand a {
    font-size: 1.5rem;
    color: #333;
    text-decoration: none;
  }
  
  .navbar__toggle {
    display: none;
  }
  
  .navbar__toggle span {
    display: block;
    width: 24px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #333;
  }
  
  .navbar__toggle span:last-child {
    margin-bottom: 0;
  }

@media (max-width: 768px) {
    .navbar__menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
      padding: 1rem;
      border-bottom: 1px solid #eee;
    }
  
    .navbar__list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  
    .navbar__item {
      margin-bottom: 1rem;
    }
  
    .navbar__item:last-child {
      margin-bottom: 0;
    }
  
    .navbar__toggle {
      display: block;
      cursor: pointer;
    }
  
    .navbar__toggle.show span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .navbar__toggle.show span:nth-child(2) {
      opacity: 0;
    }
  
    .navbar__toggle.show span:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  
    .navbar__toggle.show + .navbar__menu {
      display: block;
    }
  }