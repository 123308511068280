.image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  @media only screen and (min-width: 768px) {
    .image {
      max-width: 50%;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .image {
      max-width: 33.33%;
    }
  }
  