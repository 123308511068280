.FullPageExercise .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background: #feebcb;
}

#sidebar {
    background-color: #187663;
    padding: 10px;
    margin: 10px;
    align-content: center;
    justify-content: center;
    min-width: 200px;
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 10px;
    box-sizing: border-box;
}

.page_data {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.FullPageExercise ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 15px;
    margin-top: 0px;
}

.subdirectory_link {
    text-decoration: none;
    color: #feebcb;
    border-bottom: 1px solid #feebcb;
}

.directory_link {
    text-decoration: none;
    color: lightgreen;
    border-bottom: 1px solid #feebcb;
    margin-bottom: 20px;
}

.page_link {
    text-decoration: none;
    color: #ffffff;
    margin-top: 15px;
    margin-left: 15px;
}

.FullPageExercise li {
    margin-top: 10px;
}

.sidebar_wrapper {
    background: #7abbb3;
    height: auto;
    min-height: 100vh;
    min-width: 240px;
}

.FullPageExercise {
    max-width: 1080px;
    margin: 0 auto !important;
    float: none !important;
    background: #feebcb;
    height: auto;
}

.FullPageExercise .journal_box {
    align-content: center;
    justify-content: center;
    padding: 20px;
    background: #7abbb3;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px
}

.fixed {
    position: fixed;
    top: 0px; /* Set the position of the fixed div */
}

@media (max-width: 950px) {
    /*.FullPageExercise .container {*/
    /*flex-direction: column;*/
    /*height: auto;*/
    /*}*/
    
    /*#sidebar {*/
    /*min-width: 0;*/
    /*margin: 0;*/
    /*border-radius: 0;*/
    /*    text-align: center;*/
    /*}*/
    
    .page_data {
        box-sizing: border-box;
        padding: 10px;
        margin: 0;
    }
    
    .sidebar_wrapper {
        /*display: none;*/
        width: auto;
        position: fixed;
        top: 102px;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        overflow-y: scroll;
    }
    
    .FullPageExercise .journal_box {
        box-sizing: border-box;
        padding: 10px;
    }
    
    .subdirectory_link,
    .page_link {
    margin-top: 5px;
    }
    
    .FullPageExercise li {
    margin-top: 5px;
    }
    
    .fixed {
    position: relative;
    top: auto;
    }
}

iframe {
    width: 100%;
}

img {
    width: 100%;
}

svg {
    cursor: pointer;
}

.arrow {
    color: #feebcb;
}

#snazzy-page {
    box-sizing: border-box;
    padding: 20px;
}

.sidebar_wrapper a {
    text-decoration: none;
}

.sidebar-closed {
    transform: translateX(-100%);
    transition: transform 0.5s ease;
}