/* Styling for the list */
ul {
    list-style: none;
    padding: 0;
  }
  
.snazzy-page li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  label {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    /* display: flex; */
    align-items: center;
    /* justify-content: space-between; */
  }
  
  /* Styling for the buttons */
  .buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
  }
  
  /* Example button styles */
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0069d9;
  }
  
  button:active {
    background-color: #005cbf;
  }

  .plus-icon {
    cursor: pointer;
    margin-left: 5px;
    color: #999;
    font-size: 32px;
    font-style: bold;
  }
  
  .plus-icon:hover {
    color: #333;
  }
  
  
  label > div {
    display: flex;
    align-items: center;
  }
  
  label > div > input[type="checkbox"] {
    margin-right: 10px;
  }
  
  label > div > span {
    margin-right: 10px;
  }
  
  label > span.plus-icon {
    font-size: 24px;
    font-weight: bold;
    color: #999;
    cursor: pointer;
  }